import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addUserSignature, fetchUserSignature, setUserSignatureLoading } from '../../actions/actions_users';
import { Box } from '@chakra-ui/react';

class SignatureUpload extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    signature: PropTypes.string,
    token: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
    onValidationChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      file: false,
      error: null,
    };
  }

  componentDidMount() {
    const { dispatch, user, token } = this.props;
    dispatch(fetchUserSignature(user, token));
    this.validateSignature();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signature !== this.props.signature) {
      this.validateSignature();
    }
  }

  validateSignature = () => {
    const { signature, onValidationChange } = this.props;
    const { file } = this.state;
    
    const isValid = signature || file;
    const error = !isValid ? 'Signature is required' : null;
    
    this.setState({ error });
    if (onValidationChange) {
      onValidationChange(isValid);
    }
  };

  onFieldChange = e => {
    const file = e?.target?.files[0];
    this.setState({ file }, () => {
      this.validateSignature();
    });
  };

  save = () => {
    const { dispatch, user, token } = this.props;
    const { file } = this.state;

    if (!file) {
      this.setState({ error: 'Please select a file first' });
      return;
    }

    const payload = {
      file,
      user_id: user.id,
    };

    dispatch(setUserSignatureLoading());
    dispatch(addUserSignature(payload, token));
  };

  render() {
    const { disabled, signature, loading } = this.props;
    const { file, error } = this.state;

    const fileStyle = {
      border: '1px solid grey',
      backgroundColor: 'grey',
      height: '30px',
    };

    return (
      <Box width="100%">
        <div className="col-md-12" style={{ margin: '10px', padding: '0' }}>
          <Box marginBottom="10px">
            <h5>Signature File {!signature && <span style={{ color: 'red' }}>*</span>}</h5>
            <hr />
          </Box>
          <div className="col-sm-12 row">
            <div className="col-sm-8">
              <h6>Select signature file</h6>
              <div className="row col-sm-12" style={{ marginTop: '10px' }}>
                <div className="col-sm-3">
                  <label className="btn btn-sm btn-info btn-file" style={fileStyle}>
                    Choose file...
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      disabled={disabled}
                      onChange={this.onFieldChange}
                      required={!signature}
                    />
                    <span hidden={!file}>
                      &nbsp;&nbsp;
                      <i className="flaticon-interface" style={{ fontSize: 'inherit' }} />
                    </span>
                  </label>
                </div>
                <div className="col-sm-2">
                  <button 
                    type="button" 
                    className="btn btn-success btn-sm" 
                    onClick={this.save}
                    disabled={!file}
                  >
                    Upload
                  </button>
                </div>
                {error && (
                  <div className="col-sm-12" style={{ color: 'red', marginTop: '5px' }}>
                    {error}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <h5>Your signature:</h5>
              &nbsp;&nbsp;
              {signature && !loading ? (
                <a href={signature} target="_blank" rel="noreferrer">
                  Click to download
                </a>
              ) : null}
              {!signature && !loading ? <span>No signature loaded</span> : null}
              {loading ? <img src="img/loader.gif" alt="loading" width={100} /> : null}
            </div>
          </div>
        </div>
      </Box>
    );
  }
}

export default SignatureUpload;
